define([
  "dojo/_base/declare",
  "dojox/mvc/StoreRefController",
  "dojo/Stateful",
  "scramble/stores/Session",
  "scramble/models/User",
  "scramble/env",
  "scramble/SessionManager",
  "dojox/app/utils/hash",
  "dojo/_base/lang",
  "dojo/Deferred"
], function(
  declare,
  StoreRefController,
  Stateful,
  Session,
  User,
  env,
  SessionManager,
  hashUtils,
  lang,
  Deferred
) {

  return declare(StoreRefController, {
    constructor: function() {
      this.inherited(arguments);
      this.store || (this.store = new Session());
      this.model || (this.model = new Stateful({username: "", password: ""}));
    },

    loginAs: function(customerNumber) {
      this.store.loginAs(customerNumber)
        .then(({token}) => {
          env.set('user', null);
          env.set('sessionToken', null);
          SessionManager.clear();
          SessionManager.write(token, true);
          window.location.href = '';
        });
    },

    logout: function() {
      this.user = null;
      this.token = null;

      var dfd = this.store.logout();

      dfd.then(lang.hitch(this, function(res) {
        env.set('user', null);
        env.set('sessionToken', null);
        SessionManager.clear();
        return res;
      }));

      return dfd;
    },

    checkSession: function() {
      var token = env.get('sessionToken');
      var dfd = new Deferred();
      if (!token) {
        dfd.reject("no token found");
        return dfd;
      }

      var dfd = this.store.validateByToken(token, this._parseDashboardId());

      dfd.then(lang.hitch(this, function(result) {
        env.set('user', new User(result.user));
      }), lang.hitch(this, 'logout'));

      return dfd;
    },

    _parseDashboardId: function() {
      var params = hashUtils.getParams(window.location.hash);
      var id = params && params.force_dashboard_id;
      env.set('forceDashboardId', id);

      return id;
    }
  });

});
