define([
  "dojo/_base/declare",
  "dojo/store/JsonRest",
  "dojo/request",
  "dojox/json/ref",
  "scramble/env"
], function(
  declare,
  JsonRest,
  request,
  json,
  env
) {

  return declare('scramble.stores.Session', JsonRest, {
    target: env.get('apiTarget') + "sessions",

    validateByToken: function(token, dashboardId) {
      return request.post(`${env.get('apiTarget')}sessions/validate`, {
        data:  {"token": token, force_dashboard_id: dashboardId, exclude_groups: true},
        handleAs: 'json',
        headers: {
          'Accept': 'application/json'
        }
      });
    },

    loginAs: function(customer_number) {
      return request.post(`${this.target}/login_as`, {
        data:  { customer_number },
        handleAs: 'json',
        headers: {
          'Accept': 'application/json'
        }
      });
    },

    logout: function() {
      return request.post(`${this.target}/destroy`);
    }
  });

});
