define(
  ({
    product: '제품',
    units_bundled_label: '%{units} 유닛 번들',
    sizes_and_quantities: '크기 / 수량',
    units_singular_label: '%{units} 유닛',
    units_label: '%{units} 유닛',
    app_name: '유동적',
    none: '없음',
    select: '선택...',
    cancel: '취소',
    drop_ship_instructions: '국내 배송 한정. APO나 FPO 배송은 불가능합니다.',
    product_data_sheet_tab_technology: '기술',
    product_data_sheet_tab_overview: '개요',
    product_data_sheet_tab_details: '상세 내용',
    cart_submit_order_dealer: '주문하기',
    cart_submit_order_rep: '주문하기',
    cart_submit_order: '주문 제출',
    erp_order_number: 'ERP 주문 #',
    sap_order_number: 'SAP 주문 #',
    nda_explanation: 'NDA 설명.',
    terms_and_conditions_description: '아래를 체크하여 <a href="###" target="_blank">이용 약관</a>을 읽고 동의하는지 표시하세요.',
    discounted_total: '할인된 합계',
    upcharge: '충전 해제',
    dont_show_me_this_again: '다시 표시하지 않음.',
    okay: '확인',
    arrival_date: '배송 시작 날짜',
    sso_message: '외부 로그인',
    alt_sso_message: '대체 외부 로그인',
    alt_login_title: '로그인',
    prebook: '예약',
    ats: 'ATS',
    alternate_images: '대체 이미지',
    choose: '선택',
    new_version_available_modal: '새 버전 사용 가능',
    new_version_available_message: '애플리케이션의 새 버전을 사용할 수 있습니다',
    new_version_reload: '새 버전 로드',
    new_version_snooze: '나중에 알림 받기',
    new_version_save_and_reload: '내 작업을 저장하고 다시 로드하기',
    popup_may_be_suppressed_title: '결재 페이지 열림',
    popup_may_be_suppressed_description: '신용 카드 결재 페이지가 열렸습니다. 보이지 않으면 팝업이 차단된 프라우저의 주소란을 확인하고 이를 허용하세요.',
    popup_blocked_title: '팝업 차단됨',
    popup_blocked_description: '결재 제출을 위한 새 창이 차단되었습니다. 브라우저 설정과 확장자를 확인하고 다시 시도하세요. 문제가 계속되면 주문을 저장하고 다른 브라우저에서 Elastic을 열면 됩니다. Elastic은 Google Chrome 최신 버전에서 가장 잘 지원됩니다.',
    pay_by_credit_card: '신용카드로 결재',
    do_you_want_to_pay_by_credit_card: '신용카드로 결재하시겠습니까?',
    pay_by_credit_card_no: '아니오',
    pay_by_credit_card_yes: '예',
    cancel_order_submission_and_payment: '주문 제출 및 결재 취소',
    product_reference_short: '참조: %{reference}',
    product_reference_long: '전년도 SKU: %{reference}',
    variation_code: '변형 코드',
    pending_orders: '대기 중 주문',
    freight_discount_message: '이 배송에 포함된 항목은 %{sales_program} 배송 할인 대상입니다',
    partial_freight_discount_message: '이 배송에 포함되는 항목 중 %{sales_program} 배송 할인 대상이 아닌 항목에 대한 배송 방법을 선택하세요',
    non_qualifying_items_ship_by_default_method: '%{sales_program} 대상이 아니었던 항목은 기본 방법으로 배송됩니다',
    export_pdf: 'PDF 내보내기',
    ots: 'OTS',
    terms_and_conditions: '사용 약관',
    accept: '동의',
    back_to_orders: '주문으로 돌아가기',
    back_to_payments: '결재로 돌아가기',
    back_to_shipments: '배송으로 돌아가기',
    required: '필수',
    send_request: '요청 보내기',
    should_not_empty: '필수 입력란입니다',
    should_be_number: '숫자로 입력해야 합니다',
    incorrect_value: '값이 틀립니다',
    incorrect_phone: '전화번호가 국제번호 형식인 +xx와 일치해야 합니다',
    board_riders_description: 'Boardriders, Inc.는 전 세계 보드 라이더를 위한 브랜드 의류, 신발 및 액세서리를 디자인, 생산 및 유통하는 선도적인 액션 스포츠 및 라이프스타일 회사입니다. 회사의 의류 및 신발 브랜드는 아웃도어 액션 스포츠에 대한 열정에서 영감을 받은 젊은 감각의 사람들을 위한 캐주얼 라이프스타일을 대표합니다.',
    region_error_message: '지역을 선택해야 합니다',
    country_error_message: '국가를 선택해야 합니다',
    state_error_message: '주/도를 선택해야 합니다',
    config_error_message: '관리 설정이 누락되어 이 양식을 여는 동안 오류가 발생했습니다.',
    success: '성공',
    error: '오류',
    invalid_phone_number: '유효하지 않은 전화번호',
    invalid_email: '유효하지 않은 이메일',
    submit: '제출',
    success_message: '정보를 성공적으로 제출했습니다',
    error_message: '정보 제출 중 오류가 발생했습니다. 다시 시도하거나 문제가 계속 되면 지원팀에 연락하세요.',
    no_schema: '이 기능은 설정되지 않았습니다',
    reset: '초기화',
    quick_entry_table_header_shipment_b2c: '배송',
    enter_quantities_segmented_control_b2c_by_shipment: '배송별',
    new_document_b2c_shipment: '배송',
    boxed_order: '박스형 주문',
    boxed_order_explanation_title: '박스형 주문',
    boxed_order_explanation: '주문에 박스를 1개 이상 추가하면 수량이 그 배수만큼이 됩니다. 따라서 입력한 박스의 #개에 걸쳐 입력한 수량이 중복되는 것입니다.',
    boxes: '박스',
    units_per_box: '박스 당 단위',
    regional: '지역',
    purchase_order_tooltip: '입력한 값이 유효하지 않습니다.',
    tournament_date_is_too_late: '날짜가 %{date} 전이어야 합니다',
    tournament_date_is_too_early: '날짜는 %{date} 이후여야 합니다',
    page_notes_error: '입력값이 유효하지 않습니다.',
    order_name_not_valid: '입력한 값이 유효하지 않습니다.',
    internet_connection_lost: '인터넷 연결 끊김',
    internet_connection_lost_builder_description: '<p>인터넷에 연결되지 않았습니다. 계속하려면 다시 연결하세요. 최근 수동 혹은 자동 저장으로 작업이 저장되었습니다.</p><p>데이터 손실에 대해 우려가 된다면 아래 버튼을 클릭하여 백업 데이터 파일을 다운로드 하고 <Link>support@elasticsuite.com</Link>으로 파일을 전달하여 주문을 복원하세요.</p>',
    internet_connection_lost_description: '현재 인터넷에 연결되어 있지 않습니다. 계속하려면 다시 연결하세요.',
    download_backup_data_file: '백업 데이터 파일 다운로드',
    invalid_session: '유효하지 않은 세션',
    invalid_session_builder_description: '<p>인터넷에 연결되지 않았습니다. 계속하려면 다시 연결하세요. 최근 수동 혹은 자동 저장으로 작업이 저장되었습니다.</p><p>데이터 손실에 대해 우려가 된다면 아래 버튼을 클릭하여 백업 데이터 파일을 다운로드 하고 <Link>support@elasticsuite.com</Link>으로 파일을 전달하여 주문을 복원하세요.</p>',
    invalid_session_description: '현재 인터넷에 연결되어 있지 않습니다. 계속하려면 다시 연결하세요.',
    submit_order_description: '주문을 제출합니다. 한 번 제출된 주문은 잠금 상태가 되어 편집할 수 없습니다. 주문이 클라우드에 저장되었는지 확인하세요.',
    all_sells_final: '모든 이벤트 주문은 최종 주문이며 반품할 수 없습니다. 제출하기 전에 주문을 검토했는지 확인하세요. 여러분의 성원에 감사드립니다!',
    invalid_date: '유효하지 않은 날짜',
    date_placeholder: 'MM-DD-YYYY',
    recalculate_discounts: '할인 재계산',
    available_as_of_unrestricted: '<date /> 현재 사용 가능',
    available_as_of: '<date /> 현재 사용 가능',
    order_too_large_not_saved: '주문 저장 불가',
    order_too_large_description: '주문이 최대 한도를 초과합니다. 주문을 여러 주문으로 분할합니다.',
    access_denied: '액세스 거부됨',
    deep_link_description: '공유 링크에 액세스할 수 있는 권한이 없습니다. 브랜드 관리자에게 문의하세요.',
    close: '닫기',
    sizes: '크기',
    revisions: '수정',
    technology: '기술',
    description: '설명',
    failed_to_find_prices: '가격 찾기 실패.',
    availability: '구입 가능 여부',
    available_by: '에 구입 가능',
    total: '합계',
    today: '오늘',
    similar: '비슷하다',
    complementary: '상호 보완적',
    previous: '이전',
    next: '다음',
    add_to_shipment: '배송에 추가:',
    add_to_cart: '카트에 추가',
    remove_from_cart: '카트에서 삭제',
    units_bundled_singular_label: '%{units} 유닛 번들',
    catalog_status: '활성 제품',
    purchase_order: 'PO#',
    no_po_number: '배송에 대한 %{po}을(를) 입력하세요',
    dropped: '취소됨',
    add_all: '모든 변형 추가',
    in_cart: '카트에 있음',
    not_in_cart: '카트에 없음',
    add: '추가',
    remove: '제거',
    custom_assets: '맞춤형 애셋',
    view_discounts: '할인 보기',
    purchase_rule: '구매 규칙',
    minimum_qty: '최소 수량',
  }),
);
