define(
  ({
    shipment_variation_min_failure: '请在下单前解决所有未满足的购买限制',
    new_order_document: 'Elastic订单',
    your_order_document: '您的订单',
    no_quantities: '您的选定数量必须至少为1才能提交订单。',
    no_customer_number: '请选择一个账单地址，用于',
    no_location_number: '请选择一个送货地址，用于',
    no_arrival_date: '请选择开始发货日期，用于',
    no_cancel_date: '请选择取消日期，用于',
    invalid_cancel_date: '请选择有效的取消日期。',
    invalid_start_ship_date: '请选择效的开始发货日期，用于',
    no_po_number: '请输入一个订单号',
    po_number_exceeds_limit: '###的订单号超过了######个字符的限制。',
    po_number_duplicate: '每个订单的订单号必须是唯一的。',
    invalid_po_number: '请输入一个有效的订单号',
    invalid_page_comment: '请输入一个有效的评论',
    products_not_available: '您的购物车中有产品在您所选择的发货日期无货。',
    already_submitted: '此订单已提交。',
    already_review: '您已经将此订单提交给您的代表，该订单需要审核。',
    shared_with: '该文件已与经销商共享。它是只读的，无法提交',
    dealer_submit: '该文件正在等待您的代表审核，无法提交',
    page: '页',
    shipment: '配送',
    external_field: '请选择###，用于',
    credit_hold_explanation: '在您当前的帐户状态下，您可以创建订单，但是您无法提交。请联系您的信用经理。',
    catalog_min_failure: '此目录必须具有至少###个单位。',
    catalog_max_failure: '已超出此目录的###个单位的上限。',
    catalog_min_amount_failure: '此目录的最小总订单数为###。',
    catalog_max_amount_failure: '此目录的最大总订单数为###。',
    catalog_price_group_failure: '由于缺少价格组无法下单。',
    catalog_requires_embellishments: '所有项目必须是针对个人定制的。',
    catalog_requires_embellishments_customized: '所有项目必须是针对个人定制的。',
    arrive_on_is_in_past: '###的开始配送日期是过去的日期。请更新为今天或将来的日期。',
    shipping_account_number_invalid: '请输入一个有效的配送帐户编号',
    document_renamed: '订单已重命名。',
    document_could_not_be_renamed: '订单无法重命名。',
    has_placeholder_allocations: '部分商品在您请求的配送日期前缺货。如需继续，必须先解决此问题。',
    shipment_min_amount_failure: '每次发货的最低总金额是###',
    shipment_min_quantity_failure: '每次发货的最小总数量是###个单位。',
    shipment_max_amount_failure: '每个发货的最大总金额是###',
    shipment_max_quantity_failure: '每次发货的最大总量是###个单位。',
    order_has_shipments_with_state_restrictions: '有州限制的订单',
    shipment_summary: '配送摘要',
    choose_location: '选择位置',
    save_order_title: '您想保存当前订单吗？',
    save_order_msg: '您目前有一个未完成的订单。要将这个订单保存为草稿并打开这个订单吗？',
  }),
);
