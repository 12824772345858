define(
  ({
    continue: 'Weiter',
    cancel: 'Abbrechen',
    current_customization_will_be_reset: 'Aktuelle Anpassung wird zurückgesetzt',
    rogue_warning: 'Wenn Sie fortfahren, wird Ihre aktuelle Konfiguration zurückgesetzt und Sie starten den Anpassungsprozess erneut. Wirklich fortfahren?',
    copy: 'Kopieren',
    paste: 'Einfügen',
    copyToAll: 'In alle kopieren',
    add_to_cart: 'In den Warenkorb legen',
    addToCart: 'In den Warenkorb',
    remove: 'Entfernen',
    added: 'Hinzugefügt',
    dropped: 'Entfernt',
    units: 'Stücke',
    total: 'Summe',
    subtotal: 'Zwischensumme',
    moveToShipment: 'In den Versand verschieben',
    chooseOption: 'Eine Option auswählen',
    availability_msg: 'Einige der angeforderten Mengen sind zum Liefertermin nicht verfügbar',
    atsWarning: 'Diese Menge wird bis %{availableOn} nicht verfügbar sein.',
    inventory_warehouse: 'Bestandslager',
    notInCatalog: 'Artikel konnte nicht zum Warenkorb hinzugefügt werden',
    notInCatalogExplanation: 'Sorry, aber das Hinzufügen von Artikeln aus verschiedenen Katalogen ist nicht möglich.',
    digital_market_notInCatalogExplanation: 'Ihr Warenkorb kann jeweils nur Artikel einer Marke enthalten',
    removeFromCartSuggestion: 'Wirklich <link>Ihren Warenkorb leeren?</link>',
    digital_market_removeFromCartSuggestion: 'Sie können <link>Ihren Warenkorb leeren</link>, um diesen Artikel hinzuzufügen',
    emptyCart: 'Warenkorb leeren',
    empty: 'Leeren',
    emptyConfirmation: 'Wirklich Ihren Warenkorb leeren?',
    available_on: 'Verfügbar am',
    personalization: 'Personalisierung',
    personalize: 'Personalisieren',
    configuration: 'Konfiguration',
    configure: 'Anpassen',
    placement: 'Platzierung',
    content: 'Inhalt',
    color: 'Farbe',
    product_specification: 'Produktspezifikation',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, Logo',
    other: 'Sonstiges',
    value_might_be_stale: 'Aufgrund von Änderungen in Ihrem Warenkorb ist dieser Wert möglicherweise nicht mehr aktuell. Speichern Sie Ihre Bestellung, um sie neu zu berechnen.',
    more_details: 'Mehr Details',
    availability: 'Verfügbarkeit',
    no_scheduled_availability: 'Keine geplante Verfügbarkeit',
    not_available_until: 'Nicht verfügbar bis <date />',
    available_as_of: 'Verfügbar ab <date />',
    units_bundled_singular_label: '%{units} Stück im Paket',
    units_bundled_label: '%{units} Stücke im Paket',
    add_bundle_to_cart: 'Paket zum Warenkorb hinzufügen',
    bundles: 'Pakete',
    bundled: 'Gebündelt',
    product: 'Produkt',
    sizes_and_quantities: 'Größe / Menge',
    units_singular_label: '%{units} Stück',
    units_label: '%{units} Stücke',
    current_availability: 'Aktuelle Verfügbarkeit',
    future_availability: 'Zukünftige Verfügbarkeit',
    size: 'Größe',
    sku: 'SKU',
    availability_date: 'Datum der Verfügbarkeit',
    quantity: 'Menge',
    availability_subject_to_change: 'Verfügbarkeit kann sich ändern',
    future_availability_subject_to_change: 'Die Verfügbarkeit kann sich ändern und umfasst den Contract Lagers bestand',
    contract_inventory_availability: 'Verfügbarkeit des Contract Lagers',
    contract_inventory_footer: 'Die Verfügbarkeit des Contract Lagers wird nach Aufgabe der Bestellung aktualisiert',
    yes: 'Ja',
    no: 'Nein',
    copy_to_all_confirmation_message: 'Wirklich diesen Größenbereich in alle folgenden Stile kopieren?',
    no_configurator_data: 'Keine Konfigurator-Daten verfügbar',
    oops: 'Hoppla!',
    something_went_wrong: 'Etwas ist schief gelaufen!',
    error_message: 'Keine Sorge, wir haben den Support für Sie kontaktiert, um ihn über diesen Fehler zu informieren!',
    customize_item: 'Artikel anpassen',
    per_item_upgrades: 'Upgrades pro Artikel',
    number_of_items: 'Stückzahl',
    upgrades_total: 'Upgrades insgesamt',
    total_price_message: '* Der Gesamtpreis wird in Ihrem Warenkorb berechnet.',
    customizer_summary: 'Konfigurationsübersicht',
    save: 'Speichern',
    field_required: '%{field} erforderlich',
    review_configuration: 'Konfiguration überprüfen',
    review_configurations: 'Konfigurationen überprüfen',
    dismiss: 'Verwerfen',
    review: 'Überprüfen',
    cyrp_changed: 'Die Verfügbarkeit von Komponenten in Ihren konfigurierten Produkten kann sich geändert haben. Überprüfen Sie Ihre Produktkonfigurationen',
    cyrp_review_warning: 'Die Verfügbarkeit von Komponenten in Ihren konfigurierten Produkten kann sich geändert haben. Überprüfen Sie Ihre Produktkonfigurationen, um fortzufahren.',
    quantity_not_available: 'Diese Komponente ist derzeit in der gewünschten Menge nicht verfügbar. Bitte einen alternativen Artikel auswählen, der auf Lager ist.',
    customized: 'Angepasst',
    personalized: 'Personalisiert',
    customization: 'Anpassung',
    preview: 'Vorschau',
    preview_design: 'Design-Vorschau',
    copy_preview_url: 'Vorschau-URL kopieren',
    edit: 'Bearbeiten',
  }),
);
