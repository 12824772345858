define(
  ({
    shipment_variation_min_failure: "Risolvi eventuali limitazioni di acquisto non soddisfatte prima di effettuare l'ordine",
    new_order_document: 'Ordine di Elastic',
    your_order_document: 'Il tuo ordine',
    no_quantities: 'Devi selezionare almeno 1 articolo per inviare un ordine.',
    no_customer_number: 'Seleziona un Indirizzo di Fatturazione per',
    no_location_number: 'Seleziona un Indirizzo di Spedizione per',
    no_arrival_date: "Seleziona una Data d'Inizio della Spedizione per",
    no_cancel_date: 'Seleziona una Data di Cancellazione per',
    invalid_cancel_date: 'Seleziona una data valida per la cancellazione.',
    invalid_start_ship_date: "Seleziona una data valida d'inizio di spedizione per",
    no_po_number: "Inserisci un Ordine d'Acquisto per",
    po_number_exceeds_limit: 'Il numero PO per ### supera il limite di ###### caratteri.',
    po_number_duplicate: 'I numeri di PO devono essere unici per ogni ordine.',
    invalid_po_number: 'Inserisci un numero di PO valido',
    invalid_page_comment: 'Inserisci un commento valido',
    products_not_available: 'Alcuni prodotti nel carrello non saranno disponibili per la data di spedizione selezionataa.',
    already_submitted: 'Questo ordine è già stato inviato.',
    already_review: "Hai già inviato quest'ordine al tuo rappresentate. Dev'essere controllato.",
    shared_with: 'Questo documento è stato condiviso con un fornitore. È di sola lettura e non può essere inviato',
    dealer_submit: 'Questo documento è in attesa di revisione da parte del tuo rappresentante e non può essere inviato',
    page: 'Pagina',
    shipment: 'Spedizione',
    external_field: 'Seleziona ### per ',
    credit_hold_explanation: "Con lo stato attuale dell'ordine puoi creare un ordine ma non potrai inviarlo. Ti preghiamo di contattare il tuo Credit Manager.",
    catalog_min_failure: 'Deve avere almeno ### unità per questo catalogo.',
    catalog_max_failure: 'Limite di ### unità superato per questo catalogo.',
    catalog_min_amount_failure: "L'importo minimo dell'ordine totale per questo catalogo è ###.",
    catalog_max_amount_failure: "L'importo massimo dell'ordine totale per questo catalogo è ###.",
    catalog_price_group_failure: "L'ordine non può essere effettuato a causa della mancanza del gruppo di prezzo.",
    catalog_requires_embellishments: 'Tutti gli articoli devono essere personalizzati.',
    catalog_requires_embellishments_customized: 'Tutti gli articoli devono essere personalizzati.',
    arrive_on_is_in_past: 'La Data di inizio spedizione per ### è passata. Aggiorna questo dato ad oggi o a una data futura.',
    shipping_account_number_invalid: 'Inserisci un numero di account di spedizione valido',
    document_renamed: 'Ordine rinominato.',
    document_could_not_be_renamed: "Impossibile rinominare l'ordine.",
    has_placeholder_allocations: 'Alcuni articoli non sono disponibili entro la data di spedizione richiesta. Per continuare, questo problema deve essere risolto.',
    shipment_min_amount_failure: "L'importo totale minimo per spedizione è ###",
    shipment_min_quantity_failure: 'La quantità minima totale per spedizione è di ### unità.',
    shipment_max_amount_failure: 'Il totale massimo per spedizione è ###',
    shipment_max_quantity_failure: 'La quantità totale massima per spedizione è ### unità.',
    order_has_shipments_with_state_restrictions: "L'ordine ha spedizioni con restrizioni statali",
    shipment_summary: 'Riepilogo spedizione',
    choose_location: 'Scegli posizione',
    save_order_title: 'Vorresti salvare il tuo ordine corrente?',
    save_order_msg: 'Hai attualmente un altro ordine aperto. Vuoi salvare questo ordine come bozza e aprirne uno nuovo?',
  }),
);
