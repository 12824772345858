define([
  "dojo/_base/declare",
  "scramble/env",
  "dojo/cookie"
], function(
  declare,
  env,
  cookie
) {

  require(['scramble/env'], function(newEnv) { env = newEnv });

  var SessionManager = declare(null, {
    tokenCookieName: 'remember_user_token',
    rememberSettingCookieName: 'remember_setting',

    write: function(token, remember) {
      if (remember) {
        cookie(this.tokenCookieName, token, {expires: 14, path: '/', Secure: true, SameSite: 'Strict'});
        // #303
        cookie(this.tokenCookieName, token, {expires: 14, path: '/', Secure: true, SameSite: 'Strict',
          domain: this._normalizeCookieDomain(env.get('apiHost'))});
      } else {
        cookie(this.tokenCookieName, token, {path: '/', Secure: true, SameSite: 'Strict'});
        // #303
        cookie(this.tokenCookieName, token, {path: '/', Secure: true, SameSite: 'Strict',
          domain: this._normalizeCookieDomain(env.get('apiHost'))});
      }
      this._manageRememberSetting(remember);
    },

    getToken: function() {
      var token = cookie(this.tokenCookieName);
      if (token && token !== "undefined" && token !== "null") {
        return token;
      }

      return null;
    },

    clear: function() {
      cookie(this.tokenCookieName, null, {expires: -1, path: '/'});
      cookie(this.tokenCookieName, null, {expires: -1, path: '/',
        domain: env.get('apiHost')});
      cookie(this.tokenCookieName, null, {expires: -1,
        domain: '.' + window.location.hostname});
    },

    _normalizeCookieDomain: function(domain) {
      return domain == 'localhost' ? '' : domain;
    },

    getRememberSetting: function() {
      return cookie(this.rememberSettingCookieName) ? true : false;
    },

    _manageRememberSetting: function(isSettingOn) {
      if (isSettingOn) {
        cookie(this.rememberSettingCookieName, isSettingOn, {expires: 14, path: '/', Secure: true, SameSite: 'Strict'});
        cookie(this.rememberSettingCookieName, isSettingOn, {expires: 14, path: '/', Secure: true, SameSite: 'Strict',
          domain: this._normalizeCookieDomain(env.get('apiHost'))});
      } else {
        if (this.getRememberSetting()) {
          cookie(this.rememberSettingCookieName, null, {expires: -1, path: '/'});
          cookie(this.rememberSettingCookieName, null, {expires: -1, path: '/',
            domain: env.get('apiHost')});
        }
      }
    }
  });

  return new SessionManager();

});
