define(
  ({
    shipment_variation_min_failure: 'Åtgärda eventuella köprestriktioner innan du lägger din beställning',
    new_order_document: 'Elastic-beställning',
    your_order_document: 'Din beställning',
    no_quantities: 'Du måste ha valt ett antal på minst 1 för att kunna skicka en beställning.',
    no_customer_number: 'Välj en faktureringsadress för',
    no_location_number: 'Välj en leveransadress för',
    no_arrival_date: 'Välj ett startdatum för',
    no_cancel_date: 'Välj ett avbeställningsdatum för',
    invalid_cancel_date: 'Välj ett giltigt avbeställningsdatum.',
    invalid_start_ship_date: 'Välj ett giltigt startdatum för',
    no_po_number: 'Ange ett inköpsordernummer för',
    po_number_exceeds_limit: 'PO-nummer för ### överskrider gränsen på ###### tecken.',
    po_number_duplicate: 'Inköpsordernummer måste vara unika för varje beställning.',
    invalid_po_number: 'Ange ett giltigt inköpsordernummer',
    invalid_page_comment: 'Ange en giltig kommentar',
    products_not_available: 'Det finns produkter i din kundvagn som inte kommer att vara tillgängliga efter valt leveransdatum.',
    already_submitted: 'Denna beställning har redan skickats.',
    already_review: 'Du har redan skickat denna beställning till din försäljare. Den måste granskas.',
    shared_with: 'Dokumentet har delats med en återförsäljare. Den är skrivskyddad och kan inte skickas in',
    dealer_submit: 'Dokumentet väntar på granskning av din försäljare och kan inte skickas in',
    page: 'Sida',
    shipment: 'Leverans',
    external_field: 'Välj ### för',
    credit_hold_explanation: 'Med aktuell kontostatus kan du skapa beställningar, däremot kan du inte skicka dem. Kontakta din kreditförvaltare.',
    catalog_min_failure: 'Måste ha minst ### enheter för den här katalogen.',
    catalog_max_failure: 'Gränsen på ### enheter har överskridits för denna katalog.',
    catalog_min_amount_failure: 'Det minsta totala beställningsbeloppet för denna katalog är ###.',
    catalog_max_amount_failure: 'Det högsta totala beställningsbeloppet för denna katalog är ###.',
    catalog_price_group_failure: 'Det går inte lägga beställningar på grund av att prisgrupper saknas.',
    catalog_requires_embellishments: 'Alla produkter måste göras personliga.',
    catalog_requires_embellishments_customized: 'Alla produkter måste göras personliga.',
    arrive_on_is_in_past: 'Leveransens startdatum för ### ligger i det förflutna. Uppdatera detta så att det antingen motsvarar dagens datum eller ligger i framtiden.',
    shipping_account_number_invalid: 'Ange ett giltigt kontonummer för leverans',
    document_renamed: 'Beställningen har bytt namn.',
    document_could_not_be_renamed: 'Det gick inte att byta namn på beställningen.',
    has_placeholder_allocations: 'Vissa produkter är inte tillgängliga vid angivet avsändningsdatum. För att kunna fortsätta måste detta problem lösas.',
    shipment_min_amount_failure: 'Minsta totalbelopp per leverans är ###',
    shipment_min_quantity_failure: 'Minsta totalantal per leverans är ### enheter.',
    shipment_max_amount_failure: 'Det maximala totala beloppet per försändelse är ###',
    shipment_max_quantity_failure: 'Max ### enheter kan ingå i varje leverans.',
    order_has_shipments_with_state_restrictions: 'Beställningen innehåller artiklar med statliga begränsningar',
    shipment_summary: 'Leveransöversikt',
    choose_location: 'Välj plats',
    save_order_title: 'Vill du spara din nuvarande beställning?',
    save_order_msg: 'Du har för närvarande en annan beställning öppen. Vill du spara den beställningen som utkast och öppna den här?',
  }),
);
