define(
  ({
    product: 'Produkt',
    units_bundled_label: '%{units} jednostki zgrupowane',
    sizes_and_quantities: 'Rozmiar / Ilość',
    units_singular_label: '%{units} jednostka',
    units_label: '%{units} jednostki',
    app_name: 'Elastic',
    none: 'Brak',
    select: 'wybierz...',
    cancel: 'Anuluj',
    drop_ship_instructions: 'Tylko wysyłka krajowa. Brak wysyłki do APO lub FPO.',
    product_data_sheet_tab_technology: 'Technologia',
    product_data_sheet_tab_overview: 'Przegląd',
    product_data_sheet_tab_details: 'Szczegóły',
    cart_submit_order_dealer: 'Złóż zamówienie',
    cart_submit_order_rep: 'Złóż zamówienie',
    cart_submit_order: 'Prześlij zamówienie',
    erp_order_number: 'Nr zamówienia ERP',
    sap_order_number: 'Nr zamówienia SAP',
    nda_explanation: 'Wyjaśnienie NDA.',
    terms_and_conditions_description: 'Zaznacz poniżej, aby potwierdzić, że znasz i akceptujesz <a href="###" target="_blank">Regulamin</a>',
    discounted_total: 'Suma z rabatem',
    upcharge: 'Dopłata',
    dont_show_me_this_again: 'Nie pokazuj ponownie.',
    okay: 'Okej',
    arrival_date: 'Data rozpoczęcia wysyłki',
    sso_message: 'Login zewnętrzny',
    alt_sso_message: 'Alternatywny login zewnętrzny',
    alt_login_title: 'Logowanie',
    prebook: 'Zarezerwuj wstępnie',
    ats: 'ATS',
    alternate_images: 'Alternatywne obrazy',
    choose: 'Wybierz',
    new_version_available_modal: 'Nowa wersja dostępna',
    new_version_available_message: 'Dostępna jest nowa wersja aplikacji',
    new_version_reload: 'Wczytaj nową wersję',
    new_version_snooze: 'Przypomnij mi później',
    new_version_save_and_reload: 'Zapisz moją pracę i załaduj ponownie',
    popup_may_be_suppressed_title: 'Otwarto stronę płatności',
    popup_may_be_suppressed_description: 'Została otwarta strona płatności kartą kredytową. Jeśli jej nie widzisz, sprawdź pasek adresu przeglądarki pod kątem zablokowanego wyskakującego okienka i zezwól na nie.',
    popup_blocked_title: 'Zablokowano wyskakujące okno',
    popup_blocked_description: 'Nowe okno przesyłania płatności zostało zablokowane. Sprawdź ustawienia i rozszerzenia przeglądarki i spróbuj ponownie. Jeśli problem będzie się powtarzał, możesz zapisać zamówienie i otworzyć Elastic w innej przeglądarce. Elastic jest najlepiej obsługiwany w najnowszych wersjach przeglądarki Google Chrome.',
    pay_by_credit_card: 'Zapłać kartą kredytową',
    do_you_want_to_pay_by_credit_card: 'Chcesz zapłacić kartą kredytową?',
    pay_by_credit_card_no: 'Nie',
    pay_by_credit_card_yes: 'Tak',
    cancel_order_submission_and_payment: 'Anuluj składanie zamówienia i płatność',
    product_reference_short: 'REF.: %{reference}',
    product_reference_long: 'Kod SKU z poprzedniego roku: %{reference}',
    variation_code: 'Kod wariacji',
    pending_orders: 'Zamówienia oczekujące',
    freight_discount_message: 'Produkty w tej przesyłce kwalifikują się do zniżki kosztów wysyłki %{sales_program}',
    partial_freight_discount_message: 'Wybierz metodę wysyłki dla przedmiotów w tej przesyłce, które nie kwalifikują się do zniżki kosztów wysyłki %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Przedmioty, które nie kwalifikowały się do %{sales_program}, zostaną wysłane domyślną metodą',
    export_pdf: 'Eksportuj PDF',
    ots: 'OTS',
    terms_and_conditions: 'Regulamin',
    accept: 'Akceptuj',
    back_to_orders: 'Wróć do zamówień',
    back_to_payments: 'Wróć do płatności',
    back_to_shipments: 'Wróć do wysyłki',
    required: 'Wymagane',
    send_request: 'wyślij żądanie',
    should_not_empty: 'nie powinno być puste',
    should_be_number: 'powinno być numerem',
    incorrect_value: 'nieprawidłowa wartość',
    incorrect_phone: 'numer telefonu powinien być zgodny z formatem międzynarodowym +xx',
    board_riders_description: 'Boardriders, Inc. to wiodąca firma zajmująca się sportami akcji i stylem życia, która projektuje, produkuje i dystrybuuje markową odzież, obuwie i akcesoria dla użytkowników Boardriders na całym świecie. Marki odzieżowe i obuwnicze firmy reprezentują swobodny styl życia dla młodych ludzi, których inspiruje pasja do sportów ekstremalnych na świeżym powietrzu.',
    region_error_message: 'należy wybrać region',
    country_error_message: 'należy wybrać kraj',
    state_error_message: 'należy wybrać stan/prowincję',
    config_error_message: 'Podczas otwierania tego formularza wystąpił błąd z powodu brakujących ustawień administracyjnych.',
    success: 'Sukces',
    error: 'Błąd',
    invalid_phone_number: 'Nieprawidłowy numer telefonu',
    invalid_email: 'E-mail nieprawidłowy',
    submit: 'Prześlij',
    success_message: 'Twoje informacje zostały pomyślnie przesłane',
    error_message: 'Wystąpił błąd podczas przesyłania informacji. Spróbuj ponownie lub skontaktuj się z pomocą techniczną, jeśli problem będzie się powtarzał.',
    no_schema: 'Ta funkcja nie została skonfigurowana',
    reset: 'Resetuj',
    quick_entry_table_header_shipment_b2c: 'Wysyłka',
    enter_quantities_segmented_control_b2c_by_shipment: 'Według wysyłki',
    new_document_b2c_shipment: 'Wysyłka',
    boxed_order: 'Zamówienie w pudełku',
    boxed_order_explanation_title: 'Zamówienia w pudełkach',
    boxed_order_explanation: 'Dodanie więcej niż jednego pudełka do zamówienia działa jak mnożnik ilości. Wprowadzone ilości zostaną zduplikowane we wszystkich wprowadzonych pudełkach.',
    boxes: 'Pudełka',
    units_per_box: 'Jednostki na pudełko',
    regional: 'Regionalnie',
    purchase_order_tooltip: 'Wprowadzona wartość jest nieprawidłowa.',
    tournament_date_is_too_late: 'Data musi przypadać w dniu lub przed %{date}',
    tournament_date_is_too_early: 'Data musi przypadać w dniu %{date} lub później',
    page_notes_error: 'Wprowadzona wartość jest nieprawidłowa.',
    order_name_not_valid: 'Wprowadzona wartość jest nieprawidłowa.',
    internet_connection_lost: 'Utracono połączenie z internetem',
    internet_connection_lost_builder_description: '<p>Nie masz połączenia z internetem. Połącz się ponownie, aby kontynuować. Twoja praca została zapisana do ostatniego ręcznego lub automatycznego zapisu.</p><p>Jeśli obawiasz się utraty danych, kliknij poniższy przycisk, aby pobrać plik kopii zapasowej danych i przekazać plik do <Link> support@elasticsuite.com</Link>, aby przywrócić zamówienie.</p>',
    internet_connection_lost_description: 'Nie masz obecnie połączenia z internetem. Połącz się ponownie, aby kontynuować.',
    download_backup_data_file: 'Pobierz plik danych kopii zapasowej',
    invalid_session: 'Sesja nieprawidłowa',
    invalid_session_builder_description: '<p>Nie masz połączenia z Internetem. Aby kontynuować, połącz się ponownie. Twoja praca została zapisana w ostatniej wersji ręcznej lub automatycznej.</p><p>Jeśli obawiasz się utraty danych, kliknij poniższy przycisk, aby pobrać plik kopii zapasowej danych i prześlij go do <Link> support@elasticsuite.com</Link>, aby przywrócić zamówienie.</p>',
    invalid_session_description: 'Nie masz obecnie połączenia z Internetem. Aby kontynuować, połącz się ponownie.',
    submit_order_description: 'Twoje zamówienie zostanie wkrótce przesłane. Po przesłaniu zostanie zablokowane i nie będzie można go już edytować. Pamiętaj, że zamówienie zostanie zapisane w chmurze.',
    all_sells_final: 'Wszystkie zamówienia na wydarzenia są ostateczne i nie podlegają zwrotowi. Upewnij się, że sprawdziłeś swoje zamówienie przed wysłaniem. Dziękujemy za Twoje wsparcie!',
    invalid_date: 'Nieprawidłowa data',
    date_placeholder: 'MM-DD-RRRR',
    recalculate_discounts: 'Przelicz rabaty',
    available_as_of_unrestricted: 'Dostępne od <date />',
    available_as_of: 'Dostępne od <date />',
    order_too_large_not_saved: 'Zamówienie nie zapisane',
    order_too_large_description: 'Zamówienie przekracza maksymalne limity. Podziel zamówienie na kilka zamówień.',
    access_denied: 'Odmowa dostępu',
    deep_link_description: 'Nie masz uprawnień do dostępu do udostępnionego linku. Skontaktuj się z administratorem marki.',
    close: 'Zamknij',
    sizes: 'Rozmiary',
    revisions: 'Wersje',
    technology: 'Technologia',
    description: 'Opis',
    failed_to_find_prices: 'Nie udało się znaleźć cen.',
    availability: 'Dostępność',
    available_by: 'Dostępne do',
    total: 'Łącznie',
    today: 'Dzisiaj',
    similar: 'Podobny',
    complementary: 'Uzupełniający',
    previous: 'Poprzedni',
    next: 'Dalej',
    add_to_shipment: 'Dodaj do przesyłki:',
    add_to_cart: 'Dodaj do koszyka',
    remove_from_cart: 'Usuń z koszyka',
    units_bundled_singular_label: '%{units} jednostka zgrupowana',
    catalog_status: 'Aktywne produkty',
    purchase_order: 'Zamówienie nr',
    no_po_number: 'Podaj %{po} dla wysyłki',
    dropped: 'Usunięty',
    add_all: 'Dodaj wszystkie wersje',
    in_cart: 'W koszyku',
    not_in_cart: 'Nie w koszyku',
    add: 'Dodaj',
    remove: 'Usuń',
    custom_assets: 'Niestandardowe Zasoby',
    view_discounts: 'Wyświetl rabaty',
    purchase_rule: 'Reguła zakupu',
    minimum_qty: 'Minimalna ilość',
  }),
);
