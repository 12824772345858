define([
  "dojo/_base/declare",
  "dojo/_base/lang",
  "dojo/_base/array",
  "dojo/aspect",
  "scramble/models/Base",
  "scramble/util",
  "dojox/mvc/StatefulArray",
  "dojox/mvc/sync",
  "scramble/env",
  "scramble/models/_SharingCommon",
  "scramble/models/customCatalog/Item",
  "dojo/i18n!scramble/models/nls/customCatalog",
  "scramble/models/customCatalog/Product",
  "scramble/models/customCatalog/Asset",
  "scramble/models/customCatalog/PageBreak",
  "scramble/models/customCatalog/UserImage"
], function(
  declare,
  lang,
  array,
  aspect,
  Base,
  util,
  StatefulArray,
  sync,
  env,
  _SharingCommon,
  Item,
  nls
) {

  var isShareable  = (model) => {
    return env.get('user').canShare() &&
      _SharingCommon.isAuthor.apply(model);
  };

  var CustomCatalog = declare([Base, _SharingCommon], {
    // _SharingCommon adds:
    // isCopiedTo
    // isSharedTo
    // isAuthor

    nls: nls,

    _fields: {
      name: null,
      authorId: null,
      catalogKey: null,
      parentCatalogDates: null,
      customerNumber: null,
      items: null,
      copiedTo: [],
      copyToSelection: null,
      addCopiedTo: null,
      removeCopiedTo: null,
      sharedTo: [],
      sharedBy: '',
      alreadyPicked: null,
      updatedAt: null,
      customAssets: [],
    },

    totalQuantity: 0,

    dirty: false,

    constructor: function(params) {
      if(!this.get('name')){
        this.set('name', this.nls.new_custom_catalog);
      }
    },

    postscript: function(params) {
      if (params && typeof params._id && !params.set) {
        this.unserialize(params);
      } else if (params && params.serialized) {
        this.unserialize(params);
        delete params.serialized;
        this.inherited(arguments);
      } else {
        this.inherited(arguments);
      }

      // don't populate items if we've come from unserialize
      if (!this.get('items') && !this.get('_id')) {
        this.set('items', new StatefulArray());
      }

      setTimeout(lang.hitch(this, function() {
        this.watch(lang.hitch(this, '_onChange'));
        if (this.get('items')) {
          this.get('items').watchElements(lang.hitch(this, function() {
            this._onChange('items', arguments);
          }));
        }
      }), 0);
    },

    _onChange: function(name, oldValue, newValue) {
      if (array.indexOf(['id', '_id', 'readOnly', 'dirty', 'saving', 'needsClone', 'authorId', 'copiedTo', 'sharedTo'], name) === -1) {
        if (!this.get('dirty')) {
          this.set('dirty', true);
        }

        this.onChange.apply(this, arguments);
      }
    },

    onChange: function() {},

    _dirtySetter: function(dirty) {
      this.dirty = dirty;
    },

    _itemsHandle: null,
    _itemsSetter: function(items) {
      this.items = items;

      if (this._itemsHandle) {
        this._itemsHandle.remove();
      }

      this._itemsHandle = null;

      if (!items) {
        return;
      }

      this.set('totalQuantity', items.length);

      if (items.watchElements) {
        this._itemsHandle = items.watchElements(lang.hitch(this, function() {
          this.set('totalQuantity', items.length);
        }));
      }
    },

    _catalogGetter: function() {
      if (CustomCatalog.user && CustomCatalog.user.baseCatalogs) {
        return array.filter(CustomCatalog.user.baseCatalogs,
          'return item.key === this.catalogKey;', this)[0];
      }
    },

    getCopiedTo: function() {
      return this && this.get('copiedTo') ? this.get('copiedTo') : '';
    },

    addProducts: function(products, opts, variations) {
      if (variations && products.length > 1) {
        throw new Error("got variations for multiple products in CustomCatalog#addProducts");
      }

      var idx = this._getPositionForOpts(opts);

      var items = array.map(products, function(product, i) {
        var unfilteredVariations = array.filter(variations || product.variations, function(variation) {
          return !variation.filtered;
        });
        unfilteredVariations.sort((a, b) => a.position - b.position);
        return Item.createFromData({
          product: product,
          variationCodes: new StatefulArray(
            array.map(unfilteredVariations, 'return item.code')
          ),
          position: idx + i
        }, 'Product');
      });

      return this.addItems(items, idx);
    },

    addAssets: function(assets, opts) {
      var idx = this._getPositionForOpts(opts);

      var items = array.map(assets, function(data, i) {
        return Item.createFromData({
          asset: data,
          position: idx + i
        }, data.type || 'Asset');
      });

      return this.addItems(items, idx);
    },

    addItems: function(toBeAdded, opts) {
      if (Array.isArray(opts)) {
        // we got an array of indices to add items at, this
        // generally comes from scramble/actions/DeletePageProduct
        var indices = opts;

        array.forEach(toBeAdded, function(item, i) {
          item.position = indices[i];

          array.forEach(this.items, function(otherItem) {
            if (otherItem.position >= item.position) {
              otherItem.position += 1;
            }
          });

          this.items.splice(indices[i], 0, item);
        }, this);

        return toBeAdded;
      } else if (typeof opts == "number") {
        // we got a position to add the items at
        var targetIdx = 0;
        array.forEach(this.items, function(item, i) {
          if (item.position >= opts) {
            item.position += toBeAdded.length;
          } else {
            targetIdx = i + 1;
          }
        });

        array.forEach(toBeAdded, function(item, i) {
          item.position = opts + i;
        });

        var args = [targetIdx, 0].concat(toBeAdded);
        this.items.splice.apply(this.items, args);
      } else {
        var max = this._getMaxPosition();
        array.forEach(toBeAdded, function(item) {
          item.position = ++max;
        });

        this.items.push.apply(this.items, toBeAdded);
      }

      return toBeAdded;
    },

    addItem: function(item, idx) {
      if (typeof idx != 'undefined') {
        this.items.splice(idx, 0, item);
      } else {
        this.items.push(item);
      }

      return item;
    },

    moveItems: function(toBeMoved, opts) {
      opts = opts || {};

      var firstIndex = this._getPositionForOpts(opts);

      var oldIndices = this.removeItems(toBeMoved);

      array.forEach(toBeMoved, function(item, i) {
        var position = firstIndex + i;

        array.forEach(oldIndices, function(oldIndex) {
          if (oldIndex < position) {
            position--;
          }
        });

        item.set('position', position);

        this.addItems([item], position);
      }, this);

      return oldIndices;
    },

    _getPositionForOpts: function(opts) {
      opts = opts || {};

      if (typeof opts == 'number') {
        return opts;
      }

      if (opts.place === 'first') {
        return 0;
      }

      if (opts.before || opts.after) {
        var target = array.indexOf(this.items, opts.before || opts.after);
        return target + (opts.before ? 0 : 1);
      }

      return this._getEndPosition();
    },

    removeItems: function(toBeDeleted) {
      var items = this.get('items');

      var indices = array.map(toBeDeleted, this.removeItem, this);

      return indices;
    },

    removeItem: function(item) {
      var idx = array.indexOf(this.items, item);
      if (idx == -1) {
        console.warn("models/CustomCatalog#removeItem tried to remove an item which isn't in the model!", item);
      }

      this.items.splice(idx, 1);

      array.forEach(this.items, function(otherItem) {
        if (otherItem.position > item.position) {
          otherItem.position -= 1;
        }
      });

      return idx;
    },

    addVariationsToProduct: function(productItem, variations) {
      var validCodes = array.filter(array.map(variations, 'return item.code;'), function(code) {
        return productItem.variationCodes.indexOf(code) === -1;
      });

      if (validCodes.length === 0) {
        return;
      }

      productItem.variationCodes.push.apply(productItem.variationCodes, validCodes);
      this._onChange('variations', productItem, validCodes);
    },

    removeVariationsFromProduct: function(productItem, variations) {
      var removed = 0;

      array.forEach(array.map(variations, 'return item.code'), function(code) {
        var idx = productItem.variationCodes.indexOf(code);
        productItem.variationCodes.splice(idx, 1);
        removed += 1;
      });

      if (removed > 0) {
        this._onChange('variations', productItem, variations);
      }
    },

    isAllVariationsForProduct: function(productItem, variations) {
      var codes = array.map(variations, 'return item.code');
      return array.every(productItem.variationCodes, function(code) {
        return codes.indexOf(code) !== -1;
      });
    },

    _getMaxPosition: function() {
      var max = 0;
      array.forEach(this.items, function(item) {
        if (item.get('position') > max) {
          max = item.get('position');
        }
      });

      return max;
    },

    _getEndPosition: function() {
      return this.items.length == 0 ? 0 : this._getMaxPosition() + 1;
    },

    validateProduct: function(product) {
      if (env.getFlag('customCatalogDuplicateProducts')) return true;

      return !array.some(this.items, function(item) {
        if (item.type !== "Product") {
          return false;
        }


        return item.product.number == product.number;
      });
    },

    findProductItem: function(product) {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].type !== "Product") {
          continue;
        }

        if (this.items[i].product.number == product.number) {
          return this.items[i];
        }
      }

      return null;
    },

    itemAtIndex: function(index) {
      return this.items[index] || null;
    },

    _baseQueryGetter: function() {
      return {
        catalog: this.get('catalogKey')
      };
    },

    clone: function(keepId) {
      var params = this.serialize();
      if (!keepId) {
        delete params.id;
        delete params._id;
      }
      return new CustomCatalog(params);
    },

    _parentCatalogNameGetter: function() {
      return this.get('catalog').name;
    },

    serialize: function() {
      var items = null;
      const customAssets = [];
      if (this.get('items')) {

        items = this.get('items').map(item => item.serialize());
      }

      var obj = {
        _id:                 this.get('_id'),
        id:                  this.get('id'),
        name:                this.get('name'),
        parent_catalog_key:  this.get('catalogKey'),
        author_id: this.get('authorId'),
        copy_to_selection: this.get('copyToSelection'),
        add_copied_to: this.get('addCopiedTo'),
        remove_copied_to: this.get('removeCopiedTo'),
        shared_to: this.get('sharedTo'),
        shared_by: this.get('sharedBy'),
        updated_at: this.get('updatedAt'),
        notes_from_sharer: this.get('notesFromSharer'),
        custom_assets: this.get('customAssets'),
      };

      if (items) {
        obj.items = items;
      }

      var id;
      if (id = this.get('id')) {
        obj.id = id;
        obj._id = id;
      }

      return obj;
    },

    unserialize: function(serialized) {
      var items = null;
      if (serialized.items) {
        serialized.items = array.filter(serialized.items, function(item) {
          if (item.type !== 'Product') {
            return true;
          }

          return !!array.filter(serialized.productCache, function(cacheItem) {
            return cacheItem.number == item.product_number;
          })[0];
        });

        items = new StatefulArray(array.map(
          util.normalizePositions(serialized.items), function(item) {
          item.productCache = serialized.productCache;
          item = Item.create(item);
          aspect.after(item, 'onChange', lang.hitch(this, 'onChange'));
          return item;
        }, this));
      }

      this.set({
        _id:                serialized._id,
        id:                 serialized._id,
        name:               serialized.name,
        catalogKey:         serialized.parent_catalog_key,
        parentCatalogDates: serialized.parent_catalog_dates,
        items:              items,
        authorId: serialized.author_id,
        copiedTo: serialized.copied_to,
        sharedTo: serialized.shared_to,
        sharedBy: serialized.shared_by,
        totalCopies: serialized.total_copies,
        updatedAt: serialized.updated_at,
        customCatalogId: serialized._id,
        customAssets: serialized.custom_assets,
      });
    },

    isShareable: function() {
      return isShareable(this);
    }

  });

  CustomCatalog.isShareable = isShareable;

  sync(env, 'user', CustomCatalog, 'user', {bindDirection: sync.from});

  return CustomCatalog;

});
